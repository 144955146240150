#page.car,#page.serial{



  .car-menu{
    border-bottom: 2px solid #0071bc;
    padding-top: 40px;
    .car-name{
      font-size: 18px;
      font-weight: bold;
    }
  }
}

#page.car.configure{
  table{
    min-width: 100%;
    th{
      padding-left: 25px;
    }
  }
}
#page.serial.album{
  .image-list{
    p{
      margin-top: 30px;
      font-weight: bold;
    }
  }
}

#page.serial.image{
  .image-info{
    img{
      width: 100%;
    }
    .pre,.next{
      top:300px;
      height: 50px;
      width: 150px;
      font-size: 20px;
      color: #fff;
      background-color: #000;
      line-height: 50px;
      text-align: center;

      position: fixed;
      margin-top: 30px;
      font-weight: bold;
      a{
        color: #fff;
      }
    }
    .pre{
      left:0px;
    }
    .next{
      right: 0px;
    }
  }
}


#page.car.show{

  .configure-list{
    margin-top: 20px;
  }
  .car-title{
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
    .title{
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 3px solid #0071bc;
    }
    .right{
      line-height: 40px;
      float: right;
    }
  }

  .car-info{
    padding-top: 30px;
    .title{
      font-size: 14px;
      color: #666;
    }
    .price{
      font-weight: bold;
      color: #ff384a;
    }
    .color{
      margin-left: 20px;
      height: 20px;
      width: 20px;
      background-color: #ff0000;
    }
  }

  .car-image{
    padding-top: 40px;
    .list{
      padding-top: 30px;
    }
  }

  .car-news{
    padding-top: 40px;
    .list{
      .news{
        background-color: #f7f7f7;
        padding-top: 20px;
        padding-bottom: 20px;
        border-left: 15px solid #fff;
        border-top: 5px solid #fff;
        border-bottom: 5px solid #fff;
        small{
          color: #666;
        }
      }
    }
  }


  .car-dealer{
    margin-top: 40px;
    .type{
      color:#4d94fe;
      font-weight: bold;
    }
    .title{
      color: #666;
    }
    .price{
      color: #ff384a;
      font-weight: bold;
    }
    .ask{
      padding: 10px 20px;
      background-color: #ff384a;
      color: #fff;
    }

  }

}